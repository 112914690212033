import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        color: 'black',
        py: 2,
        textAlign: 'center',
      }}
    >
      <Container>
        <Typography variant="body1" sx={{ fontFamily: 'Texturina'}}>
          <Link component={RouterLink} to="/about" color="inherit" underline="none">
            About
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
