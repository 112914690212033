import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PowerRankings = () => {
  const navigate = useNavigate();

  const handleNavigation = (articleId) => {
    navigate(`/article/${articleId}`);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography 
        variant="h4" 
        component="h1" 
        gutterBottom
        sx={{ fontFamily: 'Texturina, serif' }} 
        >
        Power Rankings
      </Typography>
      <List>
        {Array.from({ length: 10 }, (_, index) => {
          const rank = index + 1;
          let content = "???";

          if (rank === 1) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(10)}
              >
                AR-15
              </Link>
            );
          }
          if (rank === 2) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(9)}
              >
                My Name is Jeff
              </Link>
            );
          }
          if (rank === 3) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(8)}
              >
                RezaZeGerman
              </Link>
            );
          }
          if (rank === 4) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(7)}
              >
                Opps Tryna Find Me
              </Link>
            );
          }
          if (rank === 5) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(6)}
              >
                OminousOni
              </Link>
            );
          }
          if (rank === 6) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(5)}
              >
                DrMojo
              </Link>
            );
          }
          if (rank === 7) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(4)}
              >
                Xenocide
              </Link>
            );
          } else if (rank === 8) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(3)}
              >
                Tua Lipa
              </Link>
            );
          } else if (rank === 9) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(2)}
              >
                Cumbersome Cucumbers
              </Link>
            );
          } else if (rank === 10) {
            content = (
              <Link 
                component="button"
                variant="body1"
                onClick={() => handleNavigation(1)}
              >
                Herberties
              </Link>
            );
          }

          return (
            <ListItem key={rank} sx={{ paddingY: 0.5 }}>
              <ListItemText 
                primary={`#${rank}`} 
                secondary={content} 
                sx={{ fontFamily: 'Texturina, serif' }} 
                />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default PowerRankings;
