import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CardMedia from '@mui/material/CardMedia';

const ArticleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [content, setContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchArticleContent = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/articles/article${id}.txt`);
        const text = await response.text();
        setContent(text);

        const image = `${process.env.PUBLIC_URL}/images/article${id}_image.webp`;
        setImageUrl(image);
      } catch (error) {
        console.error('Error fetching the article:', error);
        setContent('Failed to load the article content.');
      }
    };

    fetchArticleContent();
  }, [id]);

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
        <IconButton onClick={handleBackClick} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography 
          variant="h4" 
          sx={{ 
            fontFamily: 'Texturina', 
            fontSize: 'clamp(1rem, 2vw + 1rem, 2.5rem)' // Adjust the font size responsively
          }} 
          gutterBottom 
          marginLeft={2}
        >
          {location.state?.title || `Article ${id}`}
        </Typography>
        <Box width={48} />
      </Box>

      {imageUrl && (
        <CardMedia
          component="img"
          height="300"
          image={imageUrl}
          alt={`Article ${id}`}
          sx={{ marginBottom: 3 }}
        />
      )}

      <Typography 
        variant="body1" 
        paragraph 
        sx={{ 
          fontFamily: 'Texturina', 
          fontSize: 'clamp(0.5rem, 2vw + 0.5rem, 2rem)',
          whiteSpace: 'pre-wrap'
        }}
      >
        {content}
      </Typography>
    </Container>
  );
};

export default ArticleDetail;
