import React from 'react';
import { Container, Typography } from '@mui/material';

const About = () => {
  return (
    <Container sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ fontFamily: 'Texturina'}} gutterBottom align="center">
        About The Dynasty Herald
      </Typography>
      <Typography variant="body1" sx={{ fontFamily: 'Texturina'}} align="center">
        The Dynasty Herald is a satirical site dedicated to chronicling the ups and downs of a dynasty fantasy football league. Designed purely for entertainment, the stories and events depicted here are entirely fictional, with the exception of actual rosters and scores. Any resemblance to real-life events or individuals is purely coincidental. Dive in, have a laugh, and enjoy the wild world of fantasy football like never before!
      </Typography>
    </Container>
  );
};

export default About;
