import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ArticleCard = ({ article }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/article/${article.id}`, { state: { title: article.title } });
  };

  return (
    <Card onClick={handleClick} sx={{ cursor: 'pointer' }}>
      <Box sx={{ position: 'relative', paddingTop: '56.25%' /* 16:9 Aspect Ratio */ }}>
        <CardMedia
          component="div"
          image={article.imagePath}
          alt={article.title}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Box>
      <CardContent>
        <Typography variant="h5" component="div" sx={{ fontFamily: 'Texturina' }}>
          {article.title}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ArticleCard;
