import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Avatar, ButtonBase, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Header = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigation = (route) => {
    navigate(route);
    setDrawerOpen(false);
  };

  const handleClick = () => {
    navigate('/'); // Change '/' to your desired route
  };

  const menuItems = [
    { text: 'Home', route: '/' },
    { text: 'Power Rankings', route: '/power-rankings' },
    { text: 'About', route: '/about' },
  ];

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem button key={index} onClick={() => handleNavigation(item.route)}>
            <ListItemText 
              primary={item.text}
              sx={{ fontFamily: 'Texturina, serif' }} 
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" color="default">
      <Toolbar>
        {isMobile && (
          <>
            <IconButton 
              edge="start" 
              color="inherit" 
              aria-label="menu" 
              onClick={toggleDrawer(true)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {list()}
            </Drawer>
          </>
        )}
        <ButtonBase onClick={handleClick} sx={{ width: '100%' }}>
          <Box display="flex" alignItems="center" width="100%">
            <Avatar 
              src={process.env.PUBLIC_URL + "/images/dynasty_herald_logo.webp"}
              alt="Logo" 
              sx={{ width: 50, height: 50, mr: 2 }} 
            />
            <Typography 
              variant="h6" 
              component="div"
              sx={{ fontFamily: 'UnifrakturCook, cursive', mr: 1 }}
            >
              The Dynasty Herald
            </Typography>
          </Box>
        </ButtonBase>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
