import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ArticleCard from './ArticleCard';

const articles = [
  {
    id: 10,
    title: '#1 - AR-15',
    imagePath: '/images/article10_image.webp'
  },
  {
    id: 9,
    title: '#2 - My Name is Jeff',
    imagePath: '/images/article9_image.webp'
  },
  {
    id: 8,
    title: '#3 - RezaZeGerman',
    imagePath: '/images/article8_image.webp'
  },
  {
    id: 7,
    title: '#4 - Opps Tryna Find Me',
    imagePath: '/images/article7_image.webp'
  },
  {
    id: 6,
    title: '#5 - OminousOni',
    imagePath: '/images/article6_image.webp'
  },
  {
    id: 5,
    title: '#6 - DrMojo',
    imagePath: '/images/article5_image.webp'
  },
  {
    id: 4,
    title: '#7 - Xenocide',
    imagePath: '/images/article4_image.webp'
  },
  {
    id: 3,
    title: '#8 - Tua Lipa',
    imagePath: '/images/article3_image.webp'
  },
  {
    id: 2,
    title: '#9 - Cumbersome Cucumbers',
    imagePath: '/images/article2_image.webp'
  },
  {
    id: 1,
    title: '#10 - Herberties',
    imagePath: '/images/article1_image.webp',
  },
  // Add more articles here
];

const Home = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" sx={{ fontFamily: 'Texturina' }} gutterBottom>
        Latest Articles
      </Typography>
      <Grid container spacing={4}>
        {articles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <ArticleCard article={article} id={article.id} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
