import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import ArticleDetail from './ArticleDetail';
import Header from './Header';
import Footer from './Footer';
import About from './About';
import PowerRankings from './PowerRankings';

function App() {
  return (
    <Router>
      <Header />
      <div style={{ paddingBottom: '64px' }}> {/* Padding to prevent content from being hidden by the footer */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/article/:id" element={<ArticleDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/power-rankings" element={<PowerRankings />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
